import { createContext, useState, useEffect } from "react";
export const authContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isAuth, setAuth] = useState(false);
  const [Token, SetToken] = useState("");
  const [userName, setUserName] = useState(localStorage.getItem('username'));
  const [location, setLocation] = useState("");
  const [currentDayWeather, setCurrentDayWeather] = useState([]);
  //const [APIURL] = useState('http://192.168.29.75:8000')
  //const [APIURL] = useState('//14.102.73.210:8000');
  //const [APIURL] = useState('/api');
    const [APIURL] = useState('https://ab-n.onrender.com')


  const Login = (TkN) => {
    setAuth(true);
    SetToken(TkN);
  };

  const LogOut = () => {
    setAuth(false);
    SetToken("");
  };

  useEffect(() => {
    console.log('Effect is running');
    const storedUserName = localStorage.getItem('username');
    const storedToken = localStorage.getItem('token');
    console.log('Stored Username:', storedUserName);
    console.log('Stored Token:', storedToken);
  
    if (storedUserName && storedToken) {
      setAuth(true);
      setUserName(storedUserName);
      SetToken(storedToken);
    }
  }, []);
  

  return (
    <authContext.Provider value={{ isAuth, setAuth, Token, SetToken, Login, LogOut, userName, setUserName, location, setLocation, currentDayWeather, setCurrentDayWeather, APIURL }}>
      {children}
    </authContext.Provider>
  );
}